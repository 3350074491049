import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

const IndexPage = () => (
  <Layout>
    <SEO title="Fehrfeld 24" />
    <h1>Ciao</h1>
  </Layout>
);

export default IndexPage;
